import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
import NotFound from '../components/Landing/NotFound';
import Success from '../components/Landing/Success';
import TryFree from '../components/Landing/TryFree';
import Login from '../components/Login';
import Redeem from '../components/RedeemProduct/Redeem';
import Register from '../components/Register';
import PublicStorefront from '../components/Store/Storefront';

const AppRoutes: React.FC = () => {
  // const { betaAccessValidated } = useAuth();

  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      {/* <Route path="/beta-access" element={<BetaAccess /> } /> */}
      {/* <Route
      path="/beta/*"
      element={
        user || betaAccessValidated ? <BetaRoutes /> : <Navigate to="/beta-access" replace />
      }
    /> */}
      <Route path="/store" element={<PublicStorefront />} />
      <Route path="/register" element={<Register mode="giftcard" />} />
      <Route path="/register/purchase" element={<Register mode="product" />} />


      {/* <Route path="/beta/store" element={
        betaAccessValidated ? <Storefront /> : <Navigate to="/beta-access" replace />
        } />
      <Route path ="/beta/register" element={<Register mode="giftcard" />} />
      <Route path="/beta/register/purchase" element={
        betaAccessValidated ? <Register /> : <Navigate to="/beta-access" replace />
      } /> */}
    
      <Route path="/login" element={<Login />} />
      <Route path="/success" element={<Success />} />
      <Route path="/cancel" element={<Navigate to="/store" />} />
      <Route path="/redeem/:giftCardId?" element={<Redeem />} />
      <Route path="/try-free" element={<TryFree />} />
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;