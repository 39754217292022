import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUserSSO, registerUserSSO } from '../api/auth';
import overqualified_pass from '../assets/overqualified_pass.png';
import { useAuth } from '../contexts/AuthContext';
import { getMSALToken, msalInstance } from '../msalConfig';
import { logDebug, logError } from '../utils/logger';
import { showErrorToast, showSuccessToast } from '../utils/toastUtils';
import RegisterNewUser from './Registration/RegisterNewUser';
import ExistingUserLogin from './Registration/UserLogin';

const Home: React.FC = () => {

    const [loading, setLoading] = useState(false);
    // const [msalUser, setMSALUser] = useState<UserData | null>(null);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleRegisterNewUser = useCallback(async () => {
        setLoading(true);
        logDebug('Starting MSAL login process');

        try {
            const user = await getMSALToken();
            // setMSALUser(user);
            if (!user) {
                showErrorToast('Failed to login with Microsoft');
                return;
            }
            logDebug('MSAL token obtained', { user });

            try{
                const { data } = await registerUserSSO(user);
                logDebug('Logged in with SSO', data);
                login(data, data.token);
                showSuccessToast('Successfully logged in with Microsoft');
                navigate('/protected/profile');
            }catch (err) {
                logError('Error during SSO login', err);
                showErrorToast('Failed to login with Microsoft');
                msalInstance.clearCache();
            }

        } catch (error) {
            logError('Error during MSAL login', error);
            msalInstance.clearCache();
        } finally {
            setLoading(false);
        }
    }, []);

    const handleMSALLogin = useCallback(async () => {
        setLoading(true);
        logDebug('Starting MSAL login process');

        try {
            const user = await getMSALToken();
            // setMSALUser(user);
            if (!user) {
                showErrorToast('Failed to login with Microsoft');
                return;
            }
            logDebug('MSAL token obtained', { user });

            try{
                const { data } = await loginUserSSO(user);
                logDebug('Logged in with SSO', data);
                login(data, data.token);
                showSuccessToast('Successfully logged in with Microsoft');
                navigate('/protected/profile');
            }catch (err) {
                logError('Error during SSO login', err);
                showErrorToast('Failed to login with Microsoft');
                msalInstance.clearCache();
            }

        } catch (error) {
            logError('Error during MSAL login', error);
            msalInstance.clearCache();
        } finally {
            setLoading(false);
        }
    }, []);




    return (
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item xs={12} md={6}>
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Grid item xs={10} sx={{ width: '80%', alignItems: 'center' }}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt="Product Image"
                            image={overqualified_pass}
                            title="Product Image"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Overqualified! Available in Early Access!
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Join for free by registering for an account! Buy now to unlock all challenges, learn about Semiconductor processing, and Learn On!
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button fullWidth variant='contained' color="secondary" onClick={() => navigate('/store')}>
                                        Store
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button fullWidth variant='contained' color="primary" onClick={() => navigate('/try-free')}>
                                        Try Free!
                                    </Button>
                                    </Grid>
                            </Grid>
                            </>
                            {/* <Button size="small" color="primary" onClick={() => navigate('/store')}>
                                Buy Now!
                            </Button> */}
                        </CardActions>
                    </Card>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item xs={10} sx={{ width: '80%', alignItems: 'center' }}>                        
                        <ExistingUserLogin loading={loading} handleMSALLogin={handleMSALLogin} />

                    </Grid>
                    <Grid item xs={10} sx={{ width: '80%', alignItems: 'center' }}> 
                        <RegisterNewUser loading={loading} handleMSALLogin={handleRegisterNewUser} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Home;