import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import HttpIcon from '@mui/icons-material/Http';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUserSSO } from '../../api/auth';
import utd_trellis from '../../assets/utd_trellis.png';
import { useAuth } from '../../contexts/AuthContext';
import { getMSALToken, msalInstance } from '../../msalConfig';
import { logDebug, logError } from '../../utils/logger';
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';
import RegisterNewUser from '../Registration/RegisterNewUser';

const TryFree: React.FC = () => {

    const [loading, setLoading] = useState(false);
    // const [msalUser, setMSALUser] = useState<UserData | null>(null);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleRegisterNewUser = useCallback(async () => {
        setLoading(true);
        logDebug('Starting MSAL login process');

        try {
            const user = await getMSALToken();
            // setMSALUser(user);
            if (!user) {
                showErrorToast('Failed to login with Microsoft');
                return;
            }
            logDebug('MSAL token obtained', { user });

            try{
                const { data } = await registerUserSSO(user);
                logDebug('Logged in with SSO', data);
                login(data, data.token);
                showSuccessToast('Successfully logged in with Microsoft');
                navigate('/protected/profile');
            }catch (err) {
                logError('Error during SSO login', err);
                showErrorToast('Failed to login with Microsoft');
                msalInstance.clearCache();
            }

        } catch (error) {
            logError('Error during MSAL login', error);
            msalInstance.clearCache();
        } finally {
            setLoading(false);
        }
    }, []);

    // const handleMSALLogin = useCallback(async () => {
    //     setLoading(true);
    //     logDebug('Starting MSAL login process');

    //     try {
    //         const user = await getMSALToken();
    //         // setMSALUser(user);
    //         if (!user) {
    //             showErrorToast('Failed to login with Microsoft');
    //             return;
    //         }
    //         logDebug('MSAL token obtained', { user });

    //         try{
    //             const { data } = await loginUserSSO(user);
    //             logDebug('Logged in with SSO', data);
    //             login(data, data.token);
    //             showSuccessToast('Successfully logged in with Microsoft');
    //             navigate('/protected/profile');
    //         }catch (err) {
    //             logError('Error during SSO login', err);
    //             showErrorToast('Failed to login with Microsoft');
    //             msalInstance.clearCache();
    //         }

    //     } catch (error) {
    //         logError('Error during MSAL login', error);
    //         msalInstance.clearCache();
    //     } finally {
    //         setLoading(false);
    //     }
    // }, []);




    return (
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
            <Grid item xs={12} md={6}>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={10} sx={{ width: '80%', alignItems: 'center' }}>
                    <Card>
                        <CardMedia
                            component="img"
                            alt="Product Image"
                            image={utd_trellis}
                            title="Product Image"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Play for Free!
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Create an account to join our server and explore the world of Overqualified!
                                <br />
                                - Explore a digital 3D twin of UT Dallas campus with your friends
                                <br />
                                - Interact with virtual students and faculty
                                <br />
                                - Find hidden easter eggs
                                <br />
                                - View in-game leaderboards
                                <br />
                                <br />
                                {/* Join now to start your journey!
                                <br /> */}
                            </Typography>
                            
                        </CardContent>
                        <CardActions>
                            <>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button fullWidth variant='contained' color="primary" component="a" href="https://support.pedegreestudios.com/support/solutions/articles/154000183390-connecting-to-our-server" target="_blank" rel="noopener noreferrer">
                                        How to Join ↗
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <HttpIcon sx={{ color: 'action', mr: 1 }} />
                                    <Typography variant="body2">play.overqualified.games</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ElectricalServicesIcon sx={{ color: 'action', mr: 1 }} />
                                    <Typography variant="body2">19132</Typography>
                                </Grid>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <Button fullWidth disabled variant='contained' color="primary" onClick={() => undefined}>
                                        How To Play ↗
                                    </Button>
                                    </Grid> */}
                            </Grid>
                            </>
                            {/* <Button size="small" color="primary" onClick={() => navigate('/store')}>
                                Buy Now!
                            </Button> */}
                        </CardActions>
                    </Card>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    {/* <Grid item xs={10} sx={{ width: '80%', alignItems: 'center' }}>                        
                        <ExistingUserLogin loading={loading} handleMSALLogin={handleMSALLogin} />

                    </Grid> */}
                    <Grid item xs={10} sx={{ width: '80%', alignItems: 'center' }}> 
                        <RegisterNewUser loading={loading} handleMSALLogin={handleRegisterNewUser} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TryFree;